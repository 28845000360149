.thanks-text {
    margin: 5rem;
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
}

@media (max-width: 480px) {
    .thanks-text {
        margin: 3rem;
        font-size: 1.7rem;
    }
}
