.questionnaire-page-counter {
    margin-top: 0.75rem;
    opacity: .5;
    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
}

.question-title {
    font-weight: 600;
    font-size: 1.8rem;
    text-align: center;
    margin: 0.75rem 0;
    max-width: 400px;
}

.questionnaire-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.questionnaire-body.hidden {
    opacity: 0;
    transform: translateX(50px);
}

.questionnaire-body.enable-transition-animation {
    transition: opacity .3s, transform .3s
}

.questionnaire-answers-container {
    margin-bottom: 0.75rem;
}

.btn.btn-continue {
    border-radius: 16px;
    background-color: #692BFF;
    max-width: 280px;
    width: 100%;
    display: block;
    font-weight: 700;
    font-size: 1.15rem;
    font-family: 'Avenir Next', 'Avenir Next Cyr', sans-serif;
    height: 56px;
    min-width: 56px;
}

.btn.btn-continue:hover {
    background-color: #792BFF
}

@media (max-width: 480px) {
    .question-title {
        font-size: 1.4rem;
    }
}

@media (max-width: 480px) {
    .btn.btn-continue {
        height: 52px;
        min-height: 52px;
    }
}
