.get-plan-container {
    background-color: #fff;
    height: 100%;
}

.get-plan-description-container {
    margin-top: 6.2rem;
}

.get-plan-ready {
    font-weight: 600;
    font-size: 1.15rem;
    opacity: .5;
    margin-top: 2rem;
}

.get-plan-title {
    font-weight: 600;
    font-size: 1.85rem;
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    padding-left: 5%;
    padding-right: 5%;
}

.btn.btn-get-plan {
    border-radius: 16px;
    background-color: #692BFF;
    max-width: 280px;
    width: 100%;
    font-weight: 700;
    font-size: 1.2rem;
    font-family: 'Avenir Next', 'Avenir Next Cyr', sans-serif;
}

.btn.btn-get-plan:hover {
    background-color: #792BFF
}

.get-plan-divider {
    height: 1px;
    background-color: black;
    opacity: .05;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 3.5rem;
    margin-bottom: 6.5rem;
}

.get-plan-subtitle {
    font-weight: 600;
    font-size: 1.2rem;
    margin-top: .8rem;
    margin-bottom: 1.5rem;
}

.get-plan-text {
    opacity: .5;
    max-width: 400px;
    padding: 0 5%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    margin-bottom: 4rem;
    font-weight: 400;
    font-size: 1.15rem;
}

.get-plan-reviews-container {
    background-color: rgb(245, 247, 250);
    padding-top: 2.5rem;
    padding-bottom: 14vh;
    padding-left: 5%;
    padding-right: 5%;
}

.get-plan-reviews-title {
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
}

.get-plan-reviews-subtitle {
    font-size: 1.15rem;
    opacity: .5;
    margin-top: 1rem;
    margin-bottom: 3rem;
}

.get-plan-review-container-card {
    background-color: white;
    margin: 1rem;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding: 1.5rem;
    border-radius: 16px;
    box-shadow: 0 8px 17px -1px rgba(0, 0, 0, .05);
}

.get-plan-review-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.get-plan-reviewer-name {
    font-weight: 600;
    font-size: 1.1rem;
}

.get-plan-review-text {
    text-align: left;
    opacity: .5;
    font-size: 1.1rem;
    margin-top: .8rem;
}

.get-plan-sticky {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 90vh;
    width: 100%;
    z-index: 1;
    transition: opacity .3s, transform .3s;
}

.get-plan-sticky.hidden {
    opacity: 0;
    transform: translate(-50%, 50px);
}

@media (max-width: 480px) {
    .get-plan-sticky {
        top: 85%;
    }

    .get-plan-description-container {
        margin-top: 2rem;
    }

    .get-plan-reviews-container {
        padding-bottom: 18vh;
    }
}

@media (max-height: 480px) {
    .get-plan-description-container {
        margin-top: 2rem;
    }
}
