.answer-card {
    border-radius: 16px;
    box-shadow: 0 8px 16px 0 rgba(216, 216, 216, .3);
    margin: 8px auto;
    max-width: 280px;
    transition: opacity .2s;
    background-color: white;
    -webkit-tap-highlight-color: transparent;
}

.answer-card.inactive {
    opacity: .5;
}

.answer-card-content {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 12px 24px;
}

.answer-text {
    font-weight: 500;
    font-size: 1.2rem;
}

.material-icons.animated {
    transition: transform .2s;
}

.material-icons.singbot-purple {
    color: #692BFF;
}

.material-icons.hidden {
    transform: scale(0);
}

@media (max-width: 480px) {
    .answer-card-content {
        padding: 8px 24px;
    }
}
