.singbot-logo-container {
    height: 100%;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.singbot-logo {
    width: 77px;
    height: 77px;
}

@media (max-width: 480px) {
    .singbot-logo-container {
        padding-top: 15px;
    }

    .singbot-logo {
        width: 48px;
        height: 48px;
    }
}

@media (max-height: 400px) {
    .singbot-logo-container {
        height: auto;
    }
}
