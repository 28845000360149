.error-message {
    font-weight: 600;
    font-size: 2rem;
    color: red;
    text-align: center;
    margin: 5rem;
}

@media (max-width: 480px) {
    .error-message {
        margin: 3rem;
        font-size: 1.7rem;
    }
}
