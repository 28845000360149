.sign-in-title {
    font-weight: 600;
    font-size: 2rem;
    margin: 2rem 0;
}

.sign-in-firebase-ui-container {
    padding: 1rem;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 8px 16px 0 rgba(216, 216, 216, .3);
}
