.get-ready-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: opacity .2s;
    background-color: rgb(249, 249, 249);
}

.get-ready-content {
    margin-left: auto;
    margin-right: auto;
    transition: transform .2s
}

.get-ready-container.hidden {
    opacity: 0;
}

.get-ready-content.hidden {
    transform: scale(1.2);
}

.get-ready-title-1 {
    opacity: .5;
    font-size: 2rem;
}

.get-ready-title-2 {
    font-weight: 600;
    font-size: 2rem;
}

.get-ready-percentage {
    margin-top: 1rem;
    opacity: .5;
    font-size: 1.25rem;
}