.firebaseui-idp-button {
    border-radius: 16px;
    padding: 1.5rem;
    max-width: unset;
}

.firebaseui-idp-google {
    background-color: rgb(220, 78, 66)!important;
}

.firebaseui-idp-text {
    text-align: center;
}

.firebaseui-idp-google .firebaseui-idp-text {
    color: white;
}

.firebaseui-idp-google .firebaseui-idp-icon {
    background: url('/images/google.svg') no-repeat;
    background-size: cover;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    padding-left: 18px;
}

.mdl-button {
    font-family: 'Avenir Next', 'Avenir Next Cyr', sans-serif;
}
