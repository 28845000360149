.questionnaire-page-get-ready {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: #f9f9f9;
}

.questionnaire-page-get-plan {
    transition: opacity .2s;
}

.questionnaire-page-get-plan.hidden {
    position: absolute;
    transform: scale(0);
    opacity: 0;
}