body, html {
    font-family: 'Avenir Next', 'Avenir Next Cyr', sans-serif!important;
    height: 100%;
}

img {
    max-width: 100vw;
}

.vstretch {
    height: 100%;
}

.hcenter {
    margin-left: auto;
    margin-right: auto;
}
